import { Inject, Injectable } from '@angular/core';
import { ITableState, TableResponseModel, TableService } from '../../../_PMSChicstays/shared/crud-table';
import { environment } from '../../../../environments/environment';
import { HttpClient ,HttpHeaders } from '@angular/common/http';
import { forkJoin, Observable, Subject } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from '../alert/alert-modal.component';
import { BehaviorSubject } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
// import {
// 	FormArray, 
// 	FormBuilder,
// 	FormControl,
// 	FormGroup,
// 	Validators,
//   } from '@angular/forms';
  

@Injectable({
  providedIn: 'root'
})
export class CommonService extends TableService<any> {

	// MaintenanceGroup: FormGroup;

	_isLoading2$ = new BehaviorSubject<boolean>(false);
	_isLoading3$ = new BehaviorSubject<boolean>(false);
	_isLoading4$ = new BehaviorSubject<boolean>(false);

	_errorMessage2 = new BehaviorSubject<string>(''); 


	

	spareArr:any=[];
	operatorArr:any=[];


  public API_URL = `${environment.apiUrl}`;
  constructor(@Inject(HttpClient) http, private modalService: NgbModal,private httpClient: HttpClient,
  public modal: NgbActiveModal,
//   private exportfb: FormBuilder, 
  
  ) {
    super(http);



    // this.MaintenanceGroup = this.exportfb.group({
	// 	reporting_date: [''],
	// 	indicate_by: [''],
	// 	type: [''],
	// 	category_id: [''],
	// 	room_id: [''],
	// 	show_sheet: [''],
	// 	status: [''],
	// 	problam: [''],
	// 	priority: [''],
	// 	exp_date: [''],
	// 	oerattion: [''],
	// 	checklist: [''],
	// 	operation_date: [''],
	// 	hour: [''],
	// 	duration: [''],
	// 	service_cost: [''],
	// 	spareparts_cost: [''],
	// 	total_cost: [''],
	// 	charge: [''],
	// 	Image: [''],
	// 	operator_name: [''],
	// 	operator_date: [''],
	// 	ora_inizio: [''],
	// 	ora_info: [''],
	// 	spare_parts: [''],
	// 	spare_amount: [''],
	// 	spare_unit_cost: [''],
	// 	spare_total: ['']
		
	// 	});

		


  }

   private getbookser = new Subject<string>();
    getbookser$ = this.getbookser.asObservable();
    getbookingFun(mission: string) {
		// alert(mission + "asdasdasdasd") 
        this.getbookser.next(mission); 
      } 
//   payload: [];

  alert(errors) {
     const modalRef = this.modalService.open(AlertModalComponent);
     modalRef.componentInstance.errors = errors;
     modalRef.result.then(() => {

     });
   }

	get_access(){
		let get_access = JSON.parse(localStorage.getItem('access_permissions'));

		if(!get_access || get_access == "null") { 
			window.location.href = window.location.origin + '/auth/login'; 
		}
		return get_access;
	}

  deleteItems(ids: number[] = []): Observable<any> {
    const tasks$ = [];
    ids.forEach(id => {
      tasks$.push(this.delete(id));
    });
    return forkJoin(tasks$);
  }

  updateStatusForItems(ids: number[], status: number): Observable<any> {
    return this.http.get<any[]>(this.API_URL).pipe(
      map((objects: any[]) => {
        return objects.filter(c => ids.indexOf(c.id) > -1).map(c => {
          c.status = status;
          return c;
        });
      }),
      exhaustMap((objects: any[]) => {
        const tasks$ = [];
        objects.forEach(product => {
          tasks$.push(this.update(product));
        });
        return forkJoin(tasks$);
      })
    );
  }
	comman_service_funcation(data): Observable<any> {
		let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
		const headersData = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' +''+token+''
		});
		const options = { headers: headersData };
		return this.httpClient
			.post<any>(data.myurl,data,options).pipe(
				catchError(err => {
				  if(err.status == '401')
					  window.location.href = window.location.origin + '/auth/login';
				  this._errorMessage2.next(err);
				  console.error('GET ITEM BY IT', err);
				  throw err;
				}),
				finalize(() => this._isLoading3$.next(false)) );
		
	}  

	// add data to task category
	task_service_funcation(data): Observable<any> {
		let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
		const headersData = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' +''+token+''
		});
		var newpayload = {'category_name':data.category_name}
		const options = { headers: headersData };
		return this.httpClient
			.post<any>(data.myurl,newpayload,options).pipe(
				catchError(err => {
				  if(err.status == '401')
					  window.location.href = window.location.origin + '/auth/login';
				  this._errorMessage2.next(err);
				  console.error('GET ITEM BY IT', err);
				  throw err;
				}),
				finalize(() => this._isLoading3$.next(false)) );
		
	} 

	// add data to task 
	// maintenance_service_funcation(data): Observable<any> { 
	// 	let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
	// 	const headersData = new HttpHeaders({
	// 		'Content-Type': 'multipart/form-data',  
	// 		Authorization: 'Bearer ' +''+token+''
	// 	});
	// 	const options = { headers: headersData };
	// 	var myurl= `${environment.apiUrl}/tasks/tasks`;
 

	// 	return this.httpClient
	// 		.post<any>(myurl,data,options).pipe(
	// 			catchError(err => {
	// 			  if(err.status == '401')
	// 				  window.location.href = window.location.origin + '/auth/login';
	// 			  this._errorMessage2.next(err);
	// 			  console.error('GET ITEM BY IT', err);
	// 			  throw err;
	// 			}),
	// 			finalize(() => this._isLoading3$.next(false)) );
		
	// } 
	

	maintenance_service_funcation(data): Observable<any> {
		let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
		const headersData = { Authorization: 'Bearer ' + token };
		const options = { headers: headersData };
		var myurl = `${environment.apiUrl}/tasks/tasks`;
	  
		return this.httpClient
		  .post<any>(myurl, data, options).pipe(
			catchError(err => {
			  if (err.status == 401)
				window.location.href = window.location.origin + '/auth/login';
			  this._errorMessage2.next(err);
			  console.error('GET ITEM BY IT', err);
			  throw err;
			}),
			finalize(() => this._isLoading3$.next(false))
		  );
	  }

	  
	
	comman_service_funcation_get(data): Observable<any> {
		this._isLoading2$.next(true);

		let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
		const headersData = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' +''+token+''
		});
		const options = { headers: headersData };
		return this.httpClient
			.get<any>(data.myurl,options).pipe(
				catchError(err => {
				  if(err.status == '401')
					  window.location.href = window.location.origin + '/auth/login';
				  this._errorMessage2.next(err);
				  console.error('GET ITEM BY IT', err);
				  throw err;
				}),
				finalize(() => this._isLoading2$.next(false)) 
			  );
	} 
	
	comman_service_funcation_put(data): Observable<any> {
		let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
		const headersData = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' +''+token+''
		});
		const options = { headers: headersData };
		return this.httpClient
			.put<any>(data.myurl,data,options).pipe(
				catchError(err => {
				  if(err.status == '401')
					  window.location.href = window.location.origin + '/auth/login';
				  this._errorMessage2.next(err);
				  console.error('GET ITEM BY IT', err);
				  throw err;
				}),
				finalize(() => this._isLoading3$.next(false)) );
	}

		// update data task category
		task_service_funcation_put(data): Observable<any> {
			let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
			const headersData = new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' +''+token+''
			});
			var newpayload = {'category_name':data.category_name}
			const options = { headers: headersData };
			return this.httpClient
				.put<any>(data.myurl,newpayload,options).pipe(
					catchError(err => {
					  if(err.status == '401')
						  window.location.href = window.location.origin + '/auth/login';
					  this._errorMessage2.next(err);
					  console.error('GET ITEM BY IT', err);
					  throw err;
					}),
					finalize(() => this._isLoading3$.next(false)) );
		}

		// update Maintenance table data
		maintenance_service_funcation_put(data, id): Observable<any> {
			let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
			const headersData = new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' +''+token+''
			});
			const options = { headers: headersData };
			// var id  = data.id;
			var myurl= `${environment.apiUrl}/tasks/tasks/${id}`;
			return this.httpClient
				.put<any>(myurl,data,options).pipe(
					catchError(err => {
					  if(err.status == '401')
						  window.location.href = window.location.origin + '/auth/login';
					  this._errorMessage2.next(err);
					  console.error('GET ITEM BY IT', err);
					  throw err;
					}),
					finalize(() => this._isLoading3$.next(false)) );
		}



			// update Maintenance table data
		maintenance_service_funcation_post(data, id): Observable<any> {
			let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
			const headersData = new HttpHeaders({
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' +''+token+''
			});
			const options = { headers: headersData };
			// var id  = data.id;
			var myurl= `${environment.apiUrl}/tasks/tasks/${id}?_method=PUT`;
			return this.httpClient
				.post<any>(myurl,data,options).pipe( 
					catchError(err => {
					  if(err.status == '401')
						  window.location.href = window.location.origin + '/auth/login';
					  this._errorMessage2.next(err);
					  console.error('GET ITEM BY IT', err);
					  throw err;
					}),
					finalize(() => this._isLoading3$.next(false)) );
		}



	comman_service_funcation_delete(data): Observable<any> {
		let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
		const headersData = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' +''+token+''
		});
		const options = { headers: headersData };
		return this.httpClient
			.delete<any>(data.myurl,options).pipe(
				catchError(err => {
				  if(err.status == '401')
					  window.location.href = window.location.origin + '/auth/login';
				  this._errorMessage2.next(err);
				  console.error('GET ITEM BY IT', err);
				  throw err;
				}),
				finalize(() => this._isLoading3$.next(false)) );
	} 


	comman_service_funcation_delete_task(data): Observable<any> {
		let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
		const headersData = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' +''+token+''
		});
		const options = { headers: headersData };
		return this.httpClient
			.delete<any>(data.myurl,options).pipe( 
				catchError(err => {
				  if(err.status == '401')
					  window.location.href = window.location.origin + '/auth/login';
				  this._errorMessage2.next(err);
				  console.error('GET ITEM BY IT', err);
				  throw err;
				}),
				finalize(() => this._isLoading3$.next(false)) ); 
	} 



	assignroom_service_funcation(data): Observable<any> {
		let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
		const headersData = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' +''+token+''
		});
		const options = { headers: headersData };
		return this.httpClient
			.post<any>(data.myurl,data.array,options).pipe(
				catchError(err => {
				  if(err.status == '401')
					  window.location.href = window.location.origin + '/auth/login';
				  this._errorMessage2.next(err);
				  console.error('GET ITEM BY IT', err);
				  throw err;
				}),
				finalize(() => this._isLoading3$.next(false)) );
		
	}  

	Calculate_extra_Accessories(data): Observable<any> {
		this._isLoading3$.next(true);

		let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
		const headersData = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' +''+token+''
		});
		const options = { headers: headersData };
		return this.httpClient
			.post<any>(data.myurl,data.array,options).pipe(
				catchError(err => {
				  if(err.status == '401')
					  window.location.href = window.location.origin + '/auth/login';
				  this._errorMessage2.next(err);
				  console.error('GET ITEM BY IT', err);
				  throw err;
				}),
				finalize(() => this._isLoading3$.next(false)) )
		
	} 



	add_extra_ass(data): Observable<any> {
		this._isLoading4$.next(true);

		let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
		const headersData = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' +''+token+''
		});

		const options = { headers: headersData };
		return this.httpClient
			.post<any>(data.myurl,data.array,options).pipe(
				catchError(err => {
				  if(err.status == '401')
					  window.location.href = window.location.origin + '/auth/login';
				  this._errorMessage2.next(err);
				  console.error('GET ITEM BY IT', err);
				  throw err;
				}),
				finalize(() => this._isLoading4$.next(false)) ) 
		
	} 

	
	removeExtraAss(data): Observable<any> {
		this._isLoading4$.next(true);

		let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
		const headersData = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' +''+token+''
		});

		const options = { headers: headersData };
		return this.httpClient
			.post<any>(data.myurl,data.array,options).pipe(
				catchError(err => {
				  if(err.status == '401')
					  window.location.href = window.location.origin + '/auth/login';
				  this._errorMessage2.next(err);
				  console.error('GET ITEM BY IT', err);
				  throw err;
				}),
				finalize(() => this._isLoading4$.next(false)) )
		
	} 
	addAccountingData(data): Observable<any> {
		this._isLoading4$.next(true);

		let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
		const headersData = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' +''+token+''
		});

		const options = { headers: headersData };
		return this.httpClient
			.post<any>(data.myurl,data.array,options).pipe(
				catchError(err => {
				  if(err.status == '401')
					  window.location.href = window.location.origin + '/auth/login';
				  this._errorMessage2.next(err);
				  console.error('GET ITEM BY IT', err);
				  throw err;
				}),
				finalize(() => this._isLoading4$.next(false)) )
		
	} 
	getAccountingData(data): Observable<any> {
		this._isLoading2$.next(true);

		let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
		const headersData = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' +''+token+''
		});
		const options = { headers: headersData };
		return this.httpClient
			.get<any>(data.myurl,options).pipe(
				catchError(err => {
				  if(err.status == '401')
					  window.location.href = window.location.origin + '/auth/login';
				  this._errorMessage2.next(err);
				  console.error('GET ITEM BY IT', err);
				  throw err;
				}),
				finalize(() => this._isLoading2$.next(false)) 
			  );
	} 

	deleteAccountingCat(data): Observable<any> {
		this._isLoading2$.next(true);
		let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
		const headersData = new HttpHeaders({  
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' +''+token+''
		});
		const options = { headers: headersData };
		return this.httpClient
			.delete<any>(data.myurl,options).pipe(
				catchError(err => {
				  if(err.status == '401')
					  window.location.href = window.location.origin + '/auth/login';
				  this._errorMessage2.next(err);
				  console.error('GET ITEM BY IT', err);
				  throw err;
				}),
				finalize(() => this._isLoading2$.next(false)) 
			  );
	} 
	// addCategoriesgData(data)
	addCategoriesgData(data): Observable<any> {
		this._isLoading4$.next(true);

		let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
		const headersData = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' +''+token+''
		});

		const options = { headers: headersData };
		return this.httpClient
			.post<any>(data.myurl,data.array,options).pipe(
				catchError(err => {
				  if(err.status == '401')
					  window.location.href = window.location.origin + '/auth/login';
				  this._errorMessage2.next(err);
				  console.error('GET ITEM BY IT', err);
				  throw err;
				}),
				finalize(() => this._isLoading4$.next(false)) )
		
	} 
	// removeExtraAss(data, payload): Observable<any> {
	// 	console.log(payload, "asdasdda")
	// 	this._isLoading4$.next(true);
	// 	let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
	// 	const headersData = new HttpHeaders({
	// 		'Content-Type': 'application/json',
	// 		Authorization: 'Bearer ' +''+token+''
	// 	});
	// 	const options = { headers: headersData };
	// 	return this.httpClient
	// 		.post<any>(data.myurl,data.array,options).pipe( 
	// 			catchError(err => {
	// 			  if(err.status == '401')
	// 				  window.location.href = window.location.origin + '/auth/login';
	// 			  this._errorMessage2.next(err);
	// 			  console.error('GET ITEM BY IT', err);
	// 			  throw err;
	// 			}),
	// 			finalize(() => this._isLoading4$.next(false)) )
	// }

	
 
  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
}
